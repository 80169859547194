<template>
  <section class="m-index m-scroll" ref="scroll" @scroll="handleScroll($event)">
    <!-- 搜索头部 -->
    <index-search :background="bgColor" :offsetTop="offsetTop" @top="backTop"  />
    <div class="m-index-header">
      <!-- 背景色 -->
      <div class="m-index-bg" :style="`background-color:${bgColor};height:${21 - (this.offsetTop / 170) * 9}vw`">
        <div class="m-search-botttom-after" :style="`box-shadow:0 -4.53333vw 0 4.26667vw ${bgColor}`"></div>
      </div>
      <!-- v-show="fiveIcon" -->
      <div class="m-index-fiveBg"  :style="`height:${21 - (this.offsetTop / 170) * 9}vw`">
        <div class="m-search-botttom-after" :style="`box-shadow:0 -4.53333vw 0 2.26667vw ${'#000'}`"></div>
        <!-- <img class="lefticon" src="@/assets/index/home_lefticon.png" alt="">
        <img class="righticon" src="@/assets/index/home_righticon.png" alt=""> -->
      </div>
        <!-- 轮播 -->
      <van-swipe ref="swipe"
      @change="handleBanner" 
      :autoplay="3000"
      :style="`opacity:${(170-this.offsetTop) / 170}`"
      class="m-swipe" :stop-propagation="false">
          <van-swipe-item v-for="(image, index) in images" :key="index" >
            <img v-lazy="image.pic_url" class="m-banner" @click.stop="goToUrl(image.link)" />
          </van-swipe-item>
      </van-swipe>
    </div>
      <!-- 模块展示 -->
      <index-grid :data="sectionEntry" />
      <!-- 砍价专区 -->
      <!-- <div class="bargain" @click="$router.push('/zh/bargain')" v-if="true">
        <img src="@/assets/bargain/bargain.png" alt="">
      </div> -->
      <!-- 活动专区 -->
      <div style="width:100%;padding: 0 12px;" v-if="showActive">
        <index-active :data="activeList" :loading="aloading"></index-active>
      </div>
      <!-- 落地页 -->
      <div style="width:100%;padding: 0 12px;margin-top:10px;" v-if="landingShow">
        <index-landing :data="landingList" :loading="lloading"></index-landing>
      </div>

      <div class="box">
        <div class="banner-box" @click="$router.push('/first')">
          <img src="@/assets/first/firstele.png" class="banner-img">
        </div>
        <div class="haggle-box" @click="$router.push('/zh/haggleList')">
          <div class="haggle-title">
            <img src="@/assets/first/ttkj.png" alt="">
          </div>
          <div class="haggle-content">
            <van-swipe :autoplay="5000" :stop-propagation="false" :show-indicators="false">
              <van-swipe-item v-for="(item, index) in haggleList" :key="index" class="haggle-goods">
                <div class="haggle-goods-item" v-for="(it, i) in item" :key="i">
                  <img v-if="it.goods.image" :src="it.goods.image.remote_url" alt="">
                  <p>$<span>{{it.bargain_goods_sku[0].sales_price}}</span></p>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
      </div>
      
      <!-- 新品首发 -->
      <index-cell title="新品首发" value="更多"  to="/zh/newProduct" class="m-index-new"></index-cell>
      <index-new :data="newProductList" style="padding-top:0" :loading="nloading"></index-new>

      <!-- 新品预售 -->
      <index-cell title="新品预售" value="更多"  to="/zh/presale?id=6" class="m-index-new"></index-cell>
      <index-new :data="newPreSaleList" isPre="1" style="padding-top:0" :loading="nloading"></index-new>

      <!-- 热销单品 -->
      <index-cell title="热销单品" value="全部"  to="/zh/hotsale" class="m-index-margin"></index-cell>
      <goods-columns :data="hotProductList" @cartClick="handleCart" :loading="hloading"></goods-columns>

      <!-- 优质商家 -->
      <!-- <index-cell title="优质商家" value="更多"  to="/zh/merchant/list" style="margin:4px 0 12px"></index-cell>
      <index-shops :data="shop"></index-shops> -->

      <!-- 品牌好货 -->
      <!-- <index-cell title="品牌好货" value="更多品牌"  to="/zh/better?id=8" style="margin:4px 0 12px"></index-cell>
      <index-brand></index-brand> -->

      <!-- 折扣专区 -->
      <index-cell title="折扣专区" value="更多折扣"  to="/zh/discount" style="margin:4px 0 12px" class="m-index-discount">
        <img slot="icon" src="@/assets/Frame.png" alt="">
      </index-cell>
      <index-discount :data="discountList" @cartClick="handleCart"></index-discount>
      <!-- 为您推荐 -->
      <m-divider title="为您推荐" class="m-index-margin"></m-divider>
      <goods-columns @cartClick="handleCart" :data="recomnd"></goods-columns>

      <div class="beian"><a href="http://www.beian.miit.gov.cn">浙ICP备19031612号</a></div>

      <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

      <!-- 选择SKU 加入购物车 -->
      <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

      <!-- 加入购物车动画 -->
      <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

      <footer-bar />

      <!-- 夜市登录 -->
      <div  class="loginBtn" @click="LoginRules">
        <img src="@/assets/firstRZ.png" alt=""> 
      </div>
      <div class="fixed" v-if="fixedShow">
        <div class="guide">
            <div class="fixed_white" @click="$router.push('/nightmarketcoupons')">
            </div>          
        </div>
        <div class="fixed_btn" @click="stepClick">
          <img src="@/assets/market/btn3.png" alt="">
        </div>
      </div>
      <!-- 翻牌抽奖 -->
      <div class="fixed" v-if="lotteryShow">
        <div class="fixed_cart" @click="toCartClick">
          <img src="@/assets/footer/shop1.png" alt="">
        </div>
        <div class="cartFixed">
            <img src="@/assets/lottery/marker.png" alt="">
        </div>
        <div :class="giftJson.level==2?'fixedPrice':'fixedPrice2'">
          <img :src="giftJson.pic" alt="">
        </div>
         <div class="fixed_btnL" @click="lotteryClick">
          <img src="@/assets/lottery/Ikonw.png" alt="">
        </div>
      </div>
      <div class="fixed" v-if="couponShow">
        <div class="fixed_coupon">
          <img src="@/assets/coupon/coupon.png" alt="">
        </div>
        <div class="fixed_close" @click="closeClick">
          <img src="@/assets/coupon/off.png" alt="">
        </div>
      </div>
      <!-- 黑五优惠券 -->
      <div class="fixed" v-if="couponEShow">
        <div class="fixed_coupon">
          <div class="fixed_closeE" @click.prevent="closeEClick">
            <img src="@/assets/coupon/off.png" alt="">
          </div>
          <img src="@/assets/coupon/couponE.png" alt=""  @click.prevent="showEClick">
        </div>
      </div>
      <!-- 年货节优惠券 -->
      <div class="fixed" v-if="newyearShow">
        <div class="fixed_coupon">
          <img src="@/assets/coupon/couponY.png" alt=""  @click.prevent="closeYClick">
          <div class="fixed_closeE">
            <img src="@/assets/coupon/off.png" alt=""  @click.prevent="closeYClick">
          </div>
        </div>
      </div>
      <!-- 五周年优惠券 -->
      <div class="fixed" v-if="fiveyearShow">
        <div class="fixed_coupon">
          <img src="@/assets/coupon/coupon5.png" alt=""  @click.prevent="close5Click">
          <div class="fixed_closeE">
            <img src="@/assets/coupon/off.png" alt=""  @click.prevent="close5Click">
          </div>
        </div>
      </div>
      <!-- 黑五优惠券 -->
    <div class="fixed" v-if="fridayShow">
      <div class="fixed_coupon">
        <img src="@/assets/eleven/blackcoupon_zh.png" alt=""  @click.prevent="closeBClick">
        <div class="fixed_closeE">
          <img src="@/assets/eleven/button_zh.png" alt=""  @click.prevent="closeBClick">
        </div>
      </div>
    </div>
    <!-- 圣诞节优惠券 -->
    <div class="fixed" v-if="christmasShow">
      <div class="fixed_coupon">
        <img src="@/assets/eleven/coupon_zh.png" alt=""  @click.prevent="closeFClick">
      </div>
    </div>
      <div class="eleven-active" v-if="elevenShow">
        <div class="eleven-coupon">
          <img src="@/assets/index/christmas.png" alt="">
        </div>
        <div class="eleven-close" @click="handleKnow">
          <img src="@/assets/coupon/off.png" alt="">
        </div>
      </div>
      <!-- 万圣节雪花 -->
      <let-it-snow
        v-bind="snowConf"
        :show="Snowshow"    
      ></let-it-snow>
      <!-- <m-scroll-top  :show="offsetTop" :dom="$refs.scroll"></m-scroll-top> -->
  </section>
</template>

<script>
/* eslint-disable */
import IndexSearch  from '@/components/zh/index-search.vue'
import IndexGrid    from '@/components/zh/index-grid.vue'
import IndexCell    from '@/components/zh/index-cell.vue'
import IndexNew     from '@/components/zh/index-new.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import IndexShops   from '@/components/zh/index-shops.vue'
import MDivider     from '@/components/zh/m-divider.vue'
import MSku         from '@/components/zh/m-sku.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import FooterBar    from '@/components/zh/footer-bar.vue'
import MScrollTop   from '@/components/zh/m-scroll-top.vue'
import IndexBrand   from '@/components/zh/index-brand.vue'
import IndexDiscount   from '@/components/zh/index-discount.vue'
import IndexActive   from '@/components/zh/index-active.vue'
import IndexLanding   from '@/components/zh/index-landing.vue'

import { sectionList, homeNew, hotGoods, userRemGoodsList, getBanner, business, preSaleList, userAllRemGoodsList, is_gift, activityTheme, landingPage, balance_return } from '@/api/zh/index.js'
import { getTypeGoodsList } from '@/api/en/gift.js'
import { haggle_list } from '@/api/zh/haggle.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
import { checkLogin } from '@/api/zh/detail.js'

export default {
  name:'Index',
  mixins: [ MixinScroll ],
  components:{ IndexSearch, IndexGrid, IndexCell, IndexNew, GoodsColumns, IndexShops, MDivider, MSku, MAnimate, FooterBar, MScrollTop, IndexBrand, IndexActive, IndexLanding, IndexDiscount },
  data(){
    return {
      fiveIcon: false,
      snowConf: {
          windPower : 1,  
          speed : 3,
          count : 12,
          size : 10,
          opacity : 1,
          images: [
            require('../../../assets/index/drop1.png'),
            require('../../../assets/index/drop2.png'),
            require('../../../assets/index/drop3.png'),
            require('../../../assets/index/drop4.png')
          ]
      },
      Snowshow      : false,
      lotteryType   : 2,
      index         : 0,         // 当前轮播的index
      bgBol         : true,      // 判断是否滑动上去，true 为轮播在显示，需要变动  false 为已经滑动上去，不需要变动
      offsetTop     : 0,         // 向上滑动的高度
      bgColor       : "#CC423D",
      images        : [{}],
      skuModal      : false,     // 控制显示 选择sku加入购物车面板
      loading       : false,
      aloading      : false,
      hloading      : false,
      nloading      : false,
      islock        : false,     // 防止多次加载
      recomnd       : [],        // 模拟为你推荐数据
      start         : false,     // 是否开启加入购物车动画
      start_dom     : null,      // 开启动画的起点DOM 
      activeList    : [],
      newProductList: [],
      hotProductList: [],
      newPreSaleList: [],
      page          : 1,
      goodsId       : '',
      sectionEntry  : [],
      isResize      : false,
      shop          : [],
      apage         : 1,
      flag          : 1,
      loginShow     : false,
      fixedShow     : false,      // 指引图
      lotteryShow   : false,      // 抽奖指引
      giftJson      : '',         // 翻拍抽奖礼品
      len           : '',
      showActive    : false,
      couponShow    : false,      // 优惠券
      couponEShow   : false,      // 黑五优惠券
      newyearShow   : false,      // 年货节
      fiveyearShow  : false,      // 五周年 
      landingShow   : false,       // 落地页
      fridayShow    : false,       // 黑五优惠券
      christmasShow : false,       // 圣诞节
      landingList   : [],
      lloading      : false,
      elevenShow    : false,     // 圣诞活动弹窗显示
      haggleList    : [], // 砍价列表
      discountList  : [], //折扣列表
    }
  },
  created(){
    this.getDiscountList()
    this.getSectionList()
    this.getHomeNew()
    this.getHotGoods()
    this.getUserRemGoodsList()
    this.getBannerList()
    this.getShops()
    this.getPreSaleList()
    this.getActivityTheme()
    this.getLandingList()
    this.getHaggle()
    window.localStorage.removeItem("coupon");
    if(this.$route.query.coupon == 1) {
      if(localStorage.getItem("setPersonal") == '{"id":96,"username":"mooby_5ddce810bw321","nickname":"jams","sex":0,"pic":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epJ8bqJjRakiaBwh62OVRBcRE9GoUhib7R6V6Cj5S5RW6ecBibeMIZONuYYCYBxJoQe64MzcZXfDcNTQ/132","email":"jams@moobyyoho.com","phone":"17640298777","is_partner":0,"is_guest":0,"pic_url":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83epJ8bqJjRakiaBwh62OVRBcRE9GoUhib7R6V6Cj5S5RW6ecBibeMIZONuYYCYBxJoQe64MzcZXfDcNTQ/132","sex_title":"保密","identity_title":"普通会员"}') {
        localStorage.removeItem('token')
        localStorage.removeItem('token2')
      }
    }
    if(localStorage.getItem('newyear')) {
      this.elevenShow = false
    }
  },
  activated() {
    if(localStorage.getItem("pay_titleF")) {
      this.housePoster();
    }
    this.lottery();
    // this.guide();
    // this.grandoLucky();
    this.midToken();
    this.coupon();
    this.couponB();
    // this.couponChris();
    // this.couponNY();
    // this.couponFive();
    // this.couponNew();
    // this.couponE();
    // 解决因缓存问题导致轮播图渲染出错
    this.isResize = true
    // 检测小程序参数
    let isMiniProgram = this.getQueryVariable('isminiprogram') || localStorage.getItem('isMiniProgram')
    if(isMiniProgram && isMiniProgram == 1) {
      // 从小程序进入
      this.$store.state.isMiniProgram = true
      localStorage.setItem('isMiniProgram', 1)
    }
    if(localStorage.getItem("token") != null || localStorage.getItem("token2") != null) {
      this.couponShow = false
    }
    // this.Snowshow = true;
    // setTimeout(() => {
    //   this.Snowshow = false;
    // }, 3000);
  },
  methods:{
    housePoster() {
      balance_return({pay_title: localStorage.getItem("pay_titleF")}).then(res => {  
          if(res){
              localStorage.removeItem("pay_titleF")
          }
      })
    },
    // 关闭圣诞弹窗
    handleKnow() {
      this.elevenShow = false
      localStorage.setItem('newyear2', true)
      checkLogin().then(res => {
        if(res) {
          if(res.status == 1) {
            // this.$router.push('/zh/login')
            localStorage.removeItem('token')
          }
        }
      })
    },
    // 砍价列表
    getHaggle() {
      haggle_list({page:1}).then(res => {
        if(res) {
          let len = 0
           if(res.data.data.length > 6) {
            len = 6
          } else {
            len = res.data.data.length
          }
          for(var i=0;i< len; i+=2) {
            this.haggleList.push(res.data.data.slice(i, i + 2))
          }
        }
      })
    },
    // 跳转砍价详情
    goHaggle(it) {
      if(it.bargain_user_goods_sku) {
        this.$router.push({path:`/zh/haggleDetail/${it.goods_id}`,query:{haggle_id:it.bargain_user_goods_sku.id}})
      } else {
        this.$router.push({path:`/zh/haggle/${it.goods_id}`,query:{id:it.id}})
      }
    },
    // 活动落地页
    getLandingList() {
      this.lloading = false
      landingPage().then(res => {
        if(res) {
          this.landingList = res.data
          this.landingShow = true
        } else {
          this.landingShow = false
        }
      })
    },
    // 获取主题活动
    getActivityTheme() {
      this.aloading = true
      activityTheme().then(res => {
        if(res){
          this.activeList = res.data
          this.activeList.list = []
          
          if(this.activeList.goods_list.length > 9) {
            this.len = 9
          } else {
            this.len = this.activeList.goods_list.length
          }
          for(var i=0;i< this.len; i+=3) {
            this.activeList.list.push(this.activeList.goods_list.slice(i, i + 3))
          }
          this.showActive = true
        } else {
          this.showActive = false
        }
      }).finally(() => {
        this.aloading = false
      })
    },
    // 中秋活动登录
    midToken() {
      if (this.$route.query.midtoken) {
          localStorage.setItem('token', this.$route.query.midtoken)
      }
    },
    // 夜市引导页面隐藏
    stepClick() {
      let isFirstz = window.sessionStorage.getItem('isFirstz')
      if (!isFirstz) {
        window.sessionStorage.setItem('isFirstz', true);
      }
        this.fixedShow = false;
    },
    // go cart
    toCartClick() {
      this.lotteryShow = false
      this.$router.push('/zh/cart');
      sessionStorage.setItem('lottery', true);
    },
    // 翻牌抽奖逻辑
    lottery() {
      if (!sessionStorage.getItem('lottery') && this.$route.query.lottery) {
        localStorage.setItem('token', this.$route.query.lottery);
        is_gift().then(res => {  
          if(res){
            this.giftJson = res.data;
            this.lotteryShow = true
          }
      })
      }else {
        this.lotteryShow = false
      }
    },
     // 夜市登录逻辑
    guide() {
      window.sessionStorage.removeItem('isFirstz');
      if (this.$route.query.mooby_token) {
          localStorage.setItem('token', this.$route.query.mooby_token)
          localStorage.setItem('token2', this.$route.query.mooby_token)
      }
         let isFirst = window.sessionStorage.getItem('isFirstz')
      if (!isFirst &&  this.$route.query.guied == 1) {
        this.fixedShow = true;
      }else{
        this.fixedShow = false;
      }  
    },
    // 翻牌引导页面隐藏
    lotteryClick() {
      sessionStorage.setItem('lottery', true);
      this.lotteryShow = false
    },
    // 双11抽奖查看礼品逻辑
    grandoLucky() {
      if (this.$route.query.grando_token) {
        localStorage.setItem('token', this.$route.query.grando_token)
      }
    },
    // 优惠券
    coupon() {
      let isFirst = window.localStorage.getItem("coupon");
      if (!isFirst && this.$route.query.coupon == 1) {
        this.couponShow = true
      } else {
        this.couponShow = false
      }
    },
    // 黑五优惠券
    couponE() {
      let isFirst = window.localStorage.getItem("couponE");
      if (!isFirst) {
        this.couponEShow = true
      } else {
        this.couponEShow = false
      }
    },
    // 黑五优惠券
    couponB() {
      let isFirst = window.localStorage.getItem("couponB");
      if (!isFirst) {
        this.fridayShow = true
      } else {
        this.fridayShow = false
      }
    },
    couponChris() {
      let isFirst = window.localStorage.getItem("couponChris");
      if (!isFirst) {
        this.christmasShow = true
      } else {
        this.christmasShow = false
      }
    },
    // 圣诞节优惠券
    couponNew() {
      let isFirst = window.localStorage.getItem("newyear2");
      if (!isFirst) {
        this.elevenShow = true
      } else {
        this.elevenShow = false
      }
    },
    // 年货节优惠券
    couponNY() {
      let isFirst = window.localStorage.getItem("couponY");
      if (!isFirst) {
        this.newyearShow = true
      } else {
        this.newyearShow = false
      }
    },
    // 五周年优惠券
    couponFive() {
      let isFirst = window.localStorage.getItem("coupon5");
      if (!isFirst) {
        this.fiveyearShow = true
      } else {
        this.fiveyearShow = false
      }
    },
    // 优惠券隐藏
    closeClick() {
      let isFirst = window.localStorage.getItem("coupon");
      if (!isFirst) {
        window.localStorage.setItem("coupon", true);
      } 
      this.couponShow = false
    },
    // 黑五优惠券隐藏
    closeEClick() {
      let isFirst = window.localStorage.getItem("couponE");
      if (!isFirst) {
        window.localStorage.setItem("couponE", true);
      } 
      this.couponEShow = false
    },
    // 年货节优惠券隐藏
    closeYClick() {
      let isFirst = window.localStorage.getItem("couponY");
      if (!isFirst) {
        window.localStorage.setItem("couponY", true);
      } 
      this.newyearShow = false
    },
    // 5周年优惠券隐藏
    close5Click() {
      let isFirst = window.localStorage.getItem("coupon5");
      if (!isFirst) {
        window.localStorage.setItem("coupon5", true);
      } 
      this.fiveyearShow = false
    },
    // 圣诞优惠券隐藏
    closeFClick() {
       let isFirst = window.localStorage.getItem("couponChris");
      if (!isFirst) {
        window.localStorage.setItem("couponChris", true);
      } 
      this.christmasShow = false
    },
    // 圣诞优惠券隐藏
    closeBClick() {
       let isFirst = window.localStorage.getItem("couponB");
      if (!isFirst) {
        window.localStorage.setItem("couponB", true);
      } 
      this.fridayShow = false
    },
    showEClick() {
      checkLogin().then(res => {
        if(res) {
          this.$router.push('/zh/login')
          localStorage.removeItem('token')
          window.localStorage.setItem("couponE", true);
          this.couponEShow = false
        }
      })
    },
    LoginRules() {
      this.$router.push('/first?lang=zh')
    },
    getQueryVariable(variable) {
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (let i=0;i<vars.length;i++) {
              let pair = vars[i].split("=");
              if(pair[0] == variable){return pair[1];}
      }
      return(false);
    },
    backTop() {
      // 返回顶部
      let speedIndex = 20
      let documentHeight = this.$refs.scroll.scrollTop
      let timer = setInterval(() => {
          documentHeight -= documentHeight / speedIndex
            this.$refs.scroll.scrollTo(0,documentHeight)
          // 停止
          if(documentHeight <= 2) {
            this.$refs.scroll.scrollTo(0,0)
            clearInterval(timer)
          }
      }, 10)
    },
    // 轮播图切换事件
    handleBanner(index){
      this.index = index
      if(this.bgBol){
        this.bgColor = this.images[index].color || '#CC423D'
      }
    },
    // 内容滑动事件
    handleScroll(e){
      if(e.target.scrollTop > 170){
        this.offsetTop = 170
        this.bgBol   = false
        this.bgColor = '#CC423D'
        this.isResize = true
      }else{
        if(this.isResize && e.target.scrollTop <= 300) {
          // 解决因缓存问题导致轮播图渲染出错
          this.$refs.swipe.resize()
        }
        this.offsetTop = e.target.scrollTop
        this.bgBol   = true
        this.bgColor = this.images[this.index].color || '#CC423D'
      } 

      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          if(this.remAll) {
            this.apage++
          } else {
            this.page++
          }
          this.getUserRemGoodsList()
        }
        this.islock = true
      }
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout(() => {
        this.start  = true
        clearTimeout(timer)
      },300)
      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start = false
    },
    getSectionList() {
      // 获取独立板块列表
      sectionList().then(res => {
        if(res) {
          this.sectionEntry = res.data
        }
      })
    },
    getPreSaleList() {
      // 新品预售
      this.loading  = true
      preSaleList(1,{is_page:0}).then(res => {
        this.newPreSaleList = res.data
      }).finally(() => {
        this.loading  = false
      })
    },
    getHomeNew() {
      // 获取新品首发列表
      this.nloading = true
      homeNew({is_page: 0,per_page: 20}).then(res => {
        this.newProductList = res.data
      }).finally(() => {
        this.nloading = false
      })
    },
    getHotGoods() {
      // 获取热门单品列表
      this.hloading = true
      hotGoods({is_page: 0,per_page: 20}).then(res => {
        this.hotProductList = res.data
      }).finally(() => {
        this.hloading = false
      })
    },
    getUserRemGoodsList() {
      // 获取自定义为你推荐
      if(!this.remAll) {
        userRemGoodsList({page: this.page,is_page: 1,per_page: 20}).then(res => {
          this.loading  = false
          if(res) {
            this.recomnd = this.recomnd.concat(res.data.data)
            this.islock   = false
          } else {
            this.remAll = true
            this.apage = 1
            this.getUserRemGoodsList()
          }
        }).finally(() => {
          this.loading = false
        })
      } else{
        userAllRemGoodsList({page: this.apage}).then(res => {
          if(res) {
            this.islock   = false
            this.recomnd = this.recomnd.concat(res.data.data)
          }
        })
      }
    },
    getDiscountList() {
      // 获取折扣列表
      getTypeGoodsList({page: this.page, type_id: 0, cid: 5}).then(res => {
        if(res) {
          this.discountList = res.data.data.length >= 18 ? res.data.data.slice(0,18) : res.data.data;
        }
      })
    },
    getBannerList() {
      // 获取首页轮播图
      getBanner(8).then(res => {
        if(res) {
          this.images = res.data
          if(res.data.length > 0) {
            this.bgColor = res.data[0].color || '#CC423D'
          }
        }
      })
    },
    goToUrl(url) {
      // 跳转链接
      if(url.indexOf('http') != -1) {
        window.location.href = url
      } else {
        this.$router.push(url)
      }
    },

    getShops() {
      business({is_select: 1, is_page: 0 , per_page: 6}).then(res => {
        if(res) {
          this.shop = res.data
        }else{
          this.shop = []
        }
      })
    }
  },
   beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path == "/nightmarketcoupons") {
        vm.$router.replace("/zh");
      }
    });
  },
}
</script>

<style lang="less" scoped>
@import './style.less';
.fixed {
  .fixed_cart {
    width: 63px;
    height: 63px;
    position: fixed;
    bottom: 13px;
    background-color: #fff;
    left: calc(50% - 30px);
    border-radius: 50%;
    img {
      position: relative;
      left: 6%;
      top: 6%;
      width: 88%;
      height: 88%;
      border-radius: 50%;
    }
  }
   .cartFixed {
    width: 250px;
    position: fixed;
    left: 20%;
    bottom: 18vw;
    img {
      width: 100%;
    }
  }
  .fixedPrice {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 316.5px;
    height: 220px;
    bottom: 90vw;
    background-image: url("../../../assets/lottery/sencondPrizezh.png");
    background-size: 100% 100%;
    img {
      height: 80%;
      display: block;
      margin: auto;
    }
  }
  .fixedPrice2 {
    width: 316.5px;
    height: 220px;
    position: fixed;
    left: 50%;
    bottom: 90vw;
    transform: translateX(-50%);
    background-image: url("../../../assets/lottery/thirdPrizezh.png");
    background-size: 100% 100%;
    img {
      height: 80%;
      display: block;
      margin: auto;
    }
  }
  .fixed_btn {
     top: 50%;
  }
  .fixed_btnL {
    width: 220px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70vw;
    img {
      width: 90%;
      display: block;
      margin: auto;
    }
  }
  .fixed_coupon {
    position: fixed;
    width: 100%;
    height: auto;
    // height: 404px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 45%;
    img {
      width: 100%;
      display: block;
      margin: auto;
    }
    .alt {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 184px;
        &:nth-child(2) {
          width: 215px;
          margin-top: 10px;
        }
      }
    }
  }
  .fixed_close {
    position: fixed;
    width: 24px;
    height: 24px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40vw;
    img {
      width: 100%;
    }
  }
  .fixed_closeE {
    // position: fixed;
    position: relative;
    width: 165px;
    height: 48px;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    // bottom: 6vw;
    img {
      width: 100%;
    }
  }
}
.loginBtn {
  position: fixed;
  right: 10px;
  bottom: 120px;
  z-index: 998;
  display: none;
  img {
    display: block;
      width: 75px;
      // height: 70px;
      animation: scale .8s infinite linear alternate;
  }
  @keyframes scale {
     from{transform: scale(1)}
     to{transform: scale(1.2)}
  }
}
.guide { 
  top: 60vw;
}
.beian{width:100%;height:50px;line-height: 50px;
    a{display: block;width:100%;height:100%;line-height: 50px;font-size: 10px;color:#888;text-align: center}
}
.box {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 128px;
  .banner-box {
    width: 172px;
    margin-right: 7px;
    .banner-img {
      width:100%;
      height:100%
    }
  }
  .haggle-box {
    width: 172px;
    height: 128px;
    padding: 6px 8px 2px;
    border-radius: 6px;
    background: url('../../../assets/first/haggle1-bg.png') no-repeat;
    background-size: 100% 100%;
    .haggle-title {
      height: 29px;
      width: 157px;
      margin-bottom: 3px;
      img {
        height: 29px;
        // width: 157px;
        object-fit: fill;
      }
    }
    .haggle-goods {
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      
      .haggle-goods-item {
        width: 72px;
        text-align: center;
        img {
          width: 70px;
          height: 70px;
        }
        p {
          color: #EB5940;
          font-size: 12px;
          span {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.bargain {
  width: 351px;
  height: 76px;
  margin:0 auto 12px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>